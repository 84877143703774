import React from "react";

import "./promo-block.scss";

import HusKerrs from "../../images/promo-page/huskerrs.svg";
import Partie from "../../images/promo-page/partie.svg";
import COD from "../../images/promo-page/warzone.svg";
import { TrackedLink } from "..";

const PromoBlock = ({ data, pageLink, pageLinkText, joinUrl }) => {
  const { date, gameTitle, title, host, prize, backgroundSrc } = data || {};

  return (
    <div className="promo-block">
      {backgroundSrc && (
        <picture>
          <source media="(min-width: 1600px)" srcSet={backgroundSrc.xl} />
          <source media="(min-width: 1200px)" srcSet={backgroundSrc.lg} />
          <source media="(min-width: 1024px)" srcSet={backgroundSrc.md} />
          <source media="(min-width: 768px)" srcSet={backgroundSrc.sm} />
          <img
            src={backgroundSrc.xs}
            alt={title}
            aria-hidden
            loading="lazy"
            className="promo-block__bg"
          />
        </picture>
      )}
      <div className="promo-block__content">
        <span className="promo-block__date">{date}</span>
        <h2 className="promo-block__title">{gameTitle}</h2>
        <h2 className="promo-block__title">{title}</h2>
        <h2 className="promo-block__title">{prize} Prize Pool</h2>
        {joinUrl && (
          <TrackedLink type="btn btn--accent" to={joinUrl} children="Join now" />
        )}
        <div className="promo-block__footer">
          <div className="promo-block__logo-container">
            <img
              src={Partie}
              alt="promo_logo"
              loading="lazy"
              className="promo-block__logo-img"
            />
            <img
              src={HusKerrs}
              alt="promo_logo"
              loading="lazy"
              className="promo-block__logo-img"
            />
            <img
              src={COD}
              alt="promo_logo"
              loading="lazy"
              className="promo-block__logo-img"
            />
          </div>
          <span className="promo-block__footer-caption">Hosted by {host}</span>
          <TrackedLink type="promo-block__footer-link" to={pageLink} children={pageLinkText} />
          <span className="promo-block__footer-caption_2">© 2020-2021 Activision Publishing, Inc. ACTIVISION, CALL OF DUTY, CALL OF DUTY WARZONE and WARZONE are trademarks of Activision Publishing, Inc.</span>
        </div>
      </div>
    </div>
  );
};

export default PromoBlock;
